<template>
  <v-row>
    <v-col cols="2">
      <v-text-field
        value="Group"
        :label="$t('type')"
        outlined
        readonly
        disabled
        dense
      ></v-text-field>
    </v-col>
    <v-col></v-col>
    <v-col cols="2">
      <v-select
        @change="updateOperation"
        :items="operationList"
        :value="data.operation"
        :label="$t('operation')"
        :rules="[v => !!v || $t('form_rules.mandatory')]"
        itemText="label"
        itemValue="value"
        outlined
        required
        dense
      ></v-select>
    </v-col>
    <v-col>
      <v-autocomplete
        @change="updateGroup"
        :items="sortedGroupsList"
        :value="data.group_id"
        :label="$t('group')"
        :rules="[v => !!v || $t('form_rules.mandatory')]"
        itemText="group_title"
        itemValue="user_group_id"
        outlined
        required
        dense
      ></v-autocomplete>
    </v-col>
    <v-col cols="1">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="$emit('remove-condition')"
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            depressed
            fab
            dark
            x-small
            color="grey darken-2"
          >
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('remove_condition') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
  
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'GroupConditionRow',

  props: {
    data: {
      type: Object
    }
  },

  data() {
    return {
      operationList: [
        {
          label: 'Equals',
          value: 'allow'
        },
        {
          label: 'Is NOT',
          value: 'deny'
        },
      ],
    }
  },

  computed: {
    ...mapGetters('operation/filter', ['sortedGroupsList'])
  },

  methods: {
    updateOperation(operation) {
      const condition = {...this.data, operation}

      this.$emit('updated-condition', condition);
    },

    updateGroup(group_id) {
      const condition = {...this.data, group_id}

      this.$emit('updated-condition', condition);
    },
  },
}

</script>
